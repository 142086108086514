<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		:title="$route.params.archived? 'Liste des projets archivés' : 'Liste des projets en cours'"
		isBackButtonHidden
		>
			<MediumButtonSlot
			v-if="$hasRight('workspaces.createWorkspaceBtn') && !$route.params.archived"
			@click="dialog = true"
			>
				Créer un nouveau projet
			</MediumButtonSlot>
		</HeaderSlot>

		<div class="tw-flex tw-gap-[10px] tw-h-[50px]">
			<v-text-field
			class="tw-max-w-[275px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			:label="$t('search_project')"
			v-model="fn"
			@input="handleInput"
			/>

			<v-text-field
			class="tw-max-w-[275px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			:label="$t('search_campaign')"
			v-model="fc"
			@input="handleInput"
			/>

			<v-select
			class="tw-max-w-[275px]"
			clearable
			hide-details
			:label="$t('filter_type')"
			:items="campaignTypes"
			item-value="value"
			item-text="name"
			v-model="ft"
			@change="handleInput"
			/>

			<v-text-field
			class="tw-max-w-[275px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			label="Filtrer par référence"
			v-model="fr"
			@input="handleInput"
			/>

			<v-select
			v-if="$hasRight('globalActions.showOrganizationsSelect')"
			class="tw-max-w-[275px]"
			:items="customerOrganizations"
			item-value="id"
			item-text="name"
			v-model="fo"
			:label="$t('filter_organization')"
			clearable
			hide-details
			@change="handleInput"
			/>

			<v-select
			v-if="
				$hasRight('globalActions.showSitesSelect') ||
					(
						!$hasRight('globalActions.showOrganizationsSelect') &&
						$hasRight('globalActions.showSitesSelect')
					)
			"
			class="tw-max-w-[275px]"
			:items="getSites()"
			item-value="id"
			item-text="name"
			v-model="fs"
			:label="$t('filter_site')"
			clearable
			hide-details
			@change="handleInput"
			/>
		</div>

		<div class="tw-flex tw-grow tw-overflow-y-auto tw-flex-col tw-gap-[10px] tw-pr-[5px]">
			<div
			v-if="(showMacroProjet || $store.state.user.current.job === null) && $route.params.archived !== 'archived'"
			project-card
			class="tw-flex tw-flex-col tw-gap-[10px] tw-p-[10px] tw-justify-between tw-cursor-pointer tw-items-center tw-border-[#2C0703]"
			@click="redirectMacro()"
			>
				<h2 class="majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden">
					{{ $store.state.user.current.job !== null ? 'Cartographie des projets de diagnostics Amiante / HAP / HCT' : 'Cartographie des projets de diagnostics Amiante / HAP / HCT' }}
				</h2>

				<div>
					{{ $store.state.user.current.job !== null ? `Visualiser les résultats des projets de diagnostics Amiante / HAP / HCT de ${customerOrganizations.find(e => e.id === fo).name}` : 'Visualiser les résultats des projets de diagnostics Amiante / HAP / HCT de votre réseau' }}
				</div>
			</div>

			<ProjectCard
			v-for="project of displayProjects"
			:project="project"
			@archivedProject="resetFilter"
			@updateWorkspaces="closeModale()"
			/>

			<v-pagination
			v-model="page"
			:length="getPages()"
			rounded="circle"
			/>
		</div>

		<ProjectCreationModale
		v-if="dialog"
		@createProject="createProject"
		@closeModale="closeModale()"
		:isItEditModal="false"
		/>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ProjectCard from "../components/workspaces/Workspace.card.vue";
import ProjectCreationModale from "../components/workspaces/CreateWorkspace.modal.vue";

export default {
	components: {
		ProjectCard,
		ProjectCreationModale
	},
	data(){
		return {
			dialog: false,
			macroProjet: false,
			campaignTypes: [
				{name: "Auscultation", value: "auscultation"},
				{name: "Carottage", value: "coring"}
			],
			limit: 7,
			offset: 0,
			page: 1,
			displayProjects: [],
			fn: "",
			fo: false,
			fs: false,
			fc: "",
			ft: false,
			fr: "",
			workspacesInfos: null
		};
	},
	computed: {
		...mapGetters("workspace", [
			"projects", "customerOrganizations", "filterName", "filterOrga", "filterSite", "filterCampaign", "filterType", "filterReference", "pageLoaded", "projectPage", "sites"
		]),
		...mapGetters("user", ["user"]),

		showMacroProjet(){
			return !this.filterName &&
				this.filterOrga &&
				!this.filterSite &&
				this.macroProjet !== false &&
				this.macroProjet.length !== 0;
		}
	},
	watch: {
		$route: {
			handler(){
				this.purgeStore();
				this.setProjects();
			},
			deep: true
		},
		fn(){
			if(this.fn === null){
				this.resetFilter();
			}
			this.SET_FILTERNAME(this.fn);
		},
		fc(){
			if(this.fc === null){
				this.resetFilter();
			}
			this.SET_FILTERCAMPAIGN(this.fc);
		},
		fo(){
			if(this.fo === null){
				if(this.$store.state.user.current.job !== null){
					this.macroProjet = false;
				}
				this.resetFilter();
			}
			else {
				this.getMacro();
			}
			this.SET_FILTERORGA(this.fo);
		},
		fs(){
			if(this.fs === null){
				this.resetFilter();
			}
			this.SET_FILTERSITE(this.fs);
		},
		ft(){
			if(this.ft === null){
				this.resetFilter();
			}
			this.SET_FILTERTYPE(this.ft);
		},
		fr(){
			if(this.fr === null){
				this.resetFilter();
			}
			this.SET_FILTERREFERENCE(this.fr);
		},
		async page(){
			this.SET_PROJECT_PAGE(this.page);
			let sliceEnd = 0;
			this.offset = (this.page - 1) * this.limit;
			
			if(undefined === this.pageLoaded.find(e => e === this.page)){
				this.updateWorkspaces({
					workspaceId: this.$route.params.id, 
					limit: this.limit,
					offset: this.offset, 
					archived: this.$route.params.archived === "archived",
					workspaceName: this.fn,
					campaignName: this.fc,
					campaignType: this.ft,
					organization: this.fo,
					site: this.fs,
					reference: this.fr
				}).then(async e => {
					this.UPDATE_PAGELOADED(this.page);
					this.workspacesInfos = await this.$api.workspaces.workspacesInfos(
						this.user.userId,
						this.$route.params.archived === "archived",
						this.fn,
						this.fc,
						this.ft,
						this.fo,
						this.fs,
						this.fr
					);
				
					sliceEnd = this.limit + this.offset > this.workspacesInfos.count ? this.workspacesInfos.count : this.limit + this.offset;
					
					this.displayProjects = this.projects.slice(this.offset, sliceEnd);
				});
			}
			else {
				this.workspacesInfos = await this.$api.workspaces.workspacesInfos(
					this.user.userId,
					this.$route.params.archived === "archived",
					this.fn,
					this.fc,
					this.ft,
					this.fo,
					this.fs,
					this.fr
				);
				this.displayProjects = this.projects.slice(this.offset, this.limit + this.offset);
			}

		},
	},
	methods: {
		...mapActions("workspace", [
			"initStore", "purgeStore", "updateWorkspaces", "filterWorkspacesByName"
		]),
		...mapActions("user", ["waitLoadUser"]),
		...mapMutations("workspace", [
			"SET_FILTERNAME", "SET_FILTERORGA", "SET_FILTERSITE", "SET_FILTERCAMPAIGN", "SET_FILTERTYPE", "SET_FILTERREFERENCE", "UPDATE_PAGELOADED", "SET_PAGELOADED", "CLEAR_PROJECTS", "SET_PROJECT_PAGE"
		]),
		...mapMutations("project", ["SET_CAMPAIGN_PAGE"]),
		redirectMacro(){
			if(this.fo === null){
				this.$router.push("/coring-macro-campaigns");
			}
			else {
				this.$router.push({
					name: "coring-macro-campaigns-map-id",
					params: {id: this.fo}
				});
			}
		},
		refreshProjects(){
			let sliceEnd = this.limit + this.offset > this.workspacesInfos.count ? this.workspacesInfos.count : this.limit + this.offset;
					
			this.displayProjects = this.projects.slice(this.offset, sliceEnd);
		},
		closeModale(){
			this.dialog = false;
			this.updateWorkspaces({
				workspaceId: this.$route.params.id, 
				limit: this.limit,
				offset: this.offset, 
				archived: this.$route.params.archived === "archived",
				workspaceName: this.fn,
				campaignName: this.fc,
				campaignType: this.ft,
				organization: this.fo,
				site: this.fs,
				reference: this.fr
			}).then(e => {
				this.refreshProjects();
			});
		},
		createProject(workspaceForm){
			this.$api.workspaces.create(workspaceForm).then(workspace => {
				this.dialog = false;
				this.updateWorkspaces({
					workspaceId: this.$route.params.id, 
					limit: this.limit,
					offset: this.offset, 
					archived: this.$route.params.archived === "archived",
					workspaceName: this.fn,
					campaignName: this.fc,
					campaignType: this.ft,
					organization: this.fo,
					site: this.fs,
					reference: this.fr
				});

				this.resetFilter();
			});
		},
		getPages(){
			if(this.workspacesInfos !== null){
				return Math.ceil(this.workspacesInfos.count / this.limit);
			}
		},
		async resetFilter(){
			if(this.projects.length !== 0){
				this.CLEAR_PROJECTS();
				this.updateWorkspaces({
					workspaceId: this.$route.params.id, 
					limit: this.limit,
					offset: this.offset, 
					archived: this.$route.params.archived === "archived",
					workspaceName: this.fn,
					campaignName: this.fc,
					campaignType: this.ft,
					organization: this.fo,
					site: this.fs,
					reference: this.fr
				}).then(async() => {
					this.workspacesInfos = await this.$api.workspaces.workspacesInfos(
						this.user.userId,
						this.$route.params.archived === "archived",
						this.fn,
						this.fc,
						this.ft,
						this.fo,
						this.fs,
						this.fr
					);
				});
			}
			else {
				await this.initStore({
					workspaceId: this.$route.params.id,
					limit: this.limit, 
					offset: this.offset, 
					workspaceName: this.fn,
					campaignName: this.fc,
					campaignType: this.ft,
					organization: this.fo,
					site: this.fs,
					reference: this.fr
				});
			}
			if(this.workspacesInfos === null){
				this.workspacesInfos = await this.$api.workspaces.workspacesInfos(
					this.user.userId,
					this.$route.params.archived === "archived",
					this.fn,
					this.fc,
					this.ft,
					this.fo,
					this.fs,
					this.fr
				);
			}
			this.displayProjects = this.projects;
			if(this.workspacesInfos.campaignsCount !== 0){
				this.SET_PAGELOADED();
			}
		},

		handleInput(){
			clearTimeout(this.timeoutId);

			// Définissez un nouveau délai pour déclencher l'action
			if(this.fn || this.fs || this.ft || this.fc || this.fo || this.fr){
				this.timeoutId = setTimeout(() => {

					this.page = 1;
					this.offset = 0;
					// Action à effectuer après 2 secondes
					this.filterWorkspacesByName({
						archived: this.$route.params.archived === "archived",
						limit: this.limit,
						offset: this.offset,
						workspaceName: this.fn,
						campaignName: this.fc,
						campaignType: this.ft,
						organization: this.fo,
						site: this.fs,
						reference: this.fr
					}).then(async() => {
						this.workspacesInfos = await this.$api.workspaces.workspacesInfos(
							this.user.userId,
							this.$route.params.archived === "archived",
							this.fn,
							this.fc,
							this.ft,
							this.fo,
							this.fs,
							this.fr
						);
				
						this.displayProjects = this.projects;
						this.SET_PAGELOADED();
					});
				}, 1000);
			}
		},
		async setProjects(){
			this.page = 1;
			this.offset = 0;
			await this.waitLoadUser();
			this.workspacesInfos = await this.$api.workspaces.workspacesInfos(
				this.user.userId,
				this.$route.params.archived === "archived",
				this.fn,
				this.fc,
				this.ft,
				this.fo,
				this.fs,
				this.fr
			);
			await this.initStore({
				archived: this.$route.params.archived === "archived",
				limit: this.limit,
				offset: this.offset,
				workspaceName: this.fn,
				campaignName: this.fc,
				campaignType: this.ft,
				organization: this.fo,
				site: this.fs,
				reference: this.fr
			});
			this.getMacro();
			let sliceEnd = this.limit + this.offset > this.workspacesInfos.count ? this.workspacesInfos.count : this.limit + this.offset;
					
			this.displayProjects = this.projects.slice(this.offset, sliceEnd);
			if(this.workspacesInfos.count !== 0){
				this.SET_PAGELOADED();
			}
		},
		async getMacro(){
			if(this.$store.state.user.current.job === null){
				this.macroProjet = (await this.$axios.get(`/users/${this.user.userId}/macro-projects`)).data;

			}
			else if(this.fo){
				this.macroProjet = (await this.$axios.get(`/organizations/${this.fo}/macro-projects`)).data;
			}
		},
		checkFilters(){
			if(this.filterName !== false){
				this.fn = this.filterName;
			}
			if(this.filterCampaign !== false){
				this.fc = this.filterCampaign;
			}
			if(this.filterSite !== false){
				this.fs = this.filterSite;
			}
			if(this.filterType !== false){
				this.ft = this.filterType;
			}
			if(this.filterOrga !== false){
				this.fo = this.filterOrga;
			}
			if(this.filterReference !== false){
				this.fr = this.filterReference;
			}
		},
		getSites(){
			if(this.fo){
				return this.sites.filter(site => site.organization.id === this.fo);
			}
			return this.sites;
		}
	},
	async mounted(){
		this.SET_CAMPAIGN_PAGE(false);
		if(this.projectPage !== false){
			this.page = this.projectPage;
		}
		this.checkFilters();
		if(this.page === 1) this.setProjects();
	},
	destroyed(){
		this.purgeStore();
	}
};
</script>

<style lang="scss">

</style>
