<template>
	<div class="EditPopin">
		<v-card
		flat
		outlined
		class="pa-8"
		>
			<v-card-title
			class="center justify-center mb-6"
			align-items
			>
				<h3>Créer une organisation</h3>
			</v-card-title>

			<v-form
			class="form"
			v-model="isFormValid"
			@submit.prevent="confirmModifications()"
			>
				<v-row>
					<v-col cols="5">
						<v-text-field
						outlined
						autofocus
						tabindex="1"
						clearable
						label="Nom *"
						v-model="newOrganization.name"
						@input="error.display = false"
						:rules="[$rules.required]"
						dense
						ref="name"
						@focus="$refs.name.resetValidation()"
						@blur="
							newOrganization.name === '' || newOrganization.name === null
								? $refs.name.resetValidation()
								: ''
						"
						:validate-on-blur="true"
						/>

						<v-select
						dense
						outlined
						clearable
						v-if="
							$hasRight('globalActions.superAdmin') &&
								newOrganization.isInternal === false
						"
						label="Organisation parent"
						:items="parentOrganizations"
						v-model="newOrganization.parentOrganizationId"
						item-text="name"
						item-value="id"
						/>

						<v-checkbox
						class="checkbox mt-4"
						v-if="$hasRight('globalActions.superAdmin')"
						tabindex="3"
						outlined
						label="Bureau d'étude"
						v-model="newOrganization.isInternal"
						@change="newOrganization.parentOrganizationId = null"
						/>
					</v-col>

					<v-col cols="2"/>

					<v-col
					cols="5"
					align="center"
					>
						<v-row justify="center">
							<div class="center-p">
								<p class="mr-2 mb-0">
									Logo de l'organisation qui <br/>
									apparaîtra sur vos livrables :
								</p>
							</div>

							<v-img
							class="logo_image mb-5"
							:src="newOrganization.logoPath"
							/>
						</v-row>

						<div>
							<v-file-input
							class="mt-0 pt-0"
							type="file"
							accept=".png, .jpg, .jpeg"
							v-model="uploadedImg"
							label="Ajouter un logo"
							@change="inputFile"
							/>
						</div>
					</v-col>

					<p
					class="errorText"
					v-show="error.display"
					>
						{{ error.text }}
					</p>
				</v-row>
			</v-form>

			<v-card-actions class="pr-0 pl-0">
				<MediumButtonSlot @click="closePopin">
					Annuler
				</MediumButtonSlot>

				<v-spacer/>

				<HighButtonSlot
				type="submit"
				:_disabled="!isFormValid"
				@click="confirmModifications"
				>
					Valider
				</HighButtonSlot>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
export default {
	data(){
		return {
			isFormValid: false,
			error: {
				text: "",
				display: false
			},
			newOrganization: {
				name: "",
				isInternal: false,
				logoPath: null,
				SIRETCode: "",
				parentOrganizationId: null
			},
			uploadedImg: null,
			parentOrganizations: []
		};
	},
	methods: {
		inputFile(e){
			if(e === null){
				this.newOrganization.logoPath = this.uploadedImg;
			}
			else {
				this.newOrganization.logoPath = URL.createObjectURL(e);
			}
		},
		isValidForm(){
			return this.newOrganization.name.length > 3;
		},
		closePopin(){
			this.$emit("closePopin");
		},
		confirmModifications(){
			if(!this.isValidForm()){
				this.error.text = "Veuillez remplir le nom de l'organisation";
				this.error.display = true;
				return;
			}
			console.log(this.$store.state.user.current);

			if(this.uploadedImg !== null){
				var formData = new FormData();
				formData.append("file", this.uploadedImg);
				this.$api.organizations.uploadFile(formData, this.$store.state.user.current.organizationId).then(data => {
					this.newOrganization.logoPath = data;
					this.$api.organizations
					.create(this.newOrganization)
					.then(response => {
						this.$emit("organizationCreated", response);
						this.closePopin();
					});
				});
			}
			else {
				this.$api.organizations.create(this.newOrganization).then(response => {
					this.$emit("organizationCreated", response);
					this.closePopin();
				});
			}
		},
		getParentOrganization(){
			this.$api.organizations.findAll().then(organizations => {
				this.parentOrganizations = organizations.filter(
					organization => organization.parentOrganization === null
				);
			});
		}
	},
	mounted(){
		this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
		this.getParentOrganization();
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.EditPopin {
  @extend %popinView;

  .checkbox {
    margin-top: -10px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .errorText {
    color: red !important;
  }

  .logo_image {
    max-width: 136px;
    height: 136px;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 4px;
    transition: border 0.2s ease;
  }

  .center-p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 136px;
  }
}
</style>
